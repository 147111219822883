import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,

    //= status
    all: `status`,
    confirmed: `confirmed`,
    pending: `pending`,
    rejected: `rejected`,
    waiting: `waiting`,
    deleted: `deleted`,

    'cash_in/out': `cash in/out`,
    'manual_in/out': `manual in/out`,
    conversion: `conversion`,
    commission: `commission`,

    confirm: `confirm`,
    reject: `reject`,
    lvl: `LV. `,

    //= table
    edit_table_settings: `edit table settings`,
    no: `no`,
    created_date: `created`,
    login_id: `login id`,
    login_name: `login name`,
    level: `level`,
    agent: `agent`,
    type: `type`,
    before_transfer: `before transfer`,
    amount: `amount`,
    user_money: `user money`,
    status: `status`,
    updated_date: `updated`,
    deleted_date: `deleted`,
    show: `show`,
    hide: `hidden`,

    //= type
    win: `win`,
    bet: `bet`,
    cash_in: `cash in`,
    cash_out: `cash out`,
    rollback: `rollback`,
    user_cancel: `user cancel`,
    modify: `modify`,

    transaction_type: 'type',
   
    cash_in_req: 'cash in request',
    cash_out_cancel: 'cash out cancel',

    cash_out_req: 'cash out request'
  }
}
