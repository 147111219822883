import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    from: '에서',
    to: '까지',

    //= status
    all: '상태',
    confirmed: '정상',
    pending: '요청',
    rejected: '거부',
    waiting: '대기',
    deleted: '삭제',

    'cash_in/out': '입금/출금',
    'manual_in/out': '지급/회수',

    manual_in: '지급',
    point: '포인트 전환',
    conversion: '포인트 전환',
    commission: '커미션',

    confirm: '확인',
    reject: '거부',
    lvl: '레벨 ',

    //= table
    edit_table_settings: '테이블 설정 수정',
    no: '번호',
    created_date: '등록일자',
    login_id: '아이디',
    login_name: '닉네임',
    level: '레벨',
    agent: '매장',
    type: '구분',
    before_transfer: '이전금액',
    amount: '금액',
    user_money: '변경금액',
    status: '상태',
    updated_date: '수정일자',
    deleted_date: '삭제일자',
    show: '보이기',
    hide: '감추기',

    //= type
    win: '당첨',
    bet: '배팅',
    cash_in: '입금',
    cash_out: '출금',
    rollback: '롤백',
    user_cancel: '배팅 취소',

    modify: '수정',
    manual_out: '회수',
    rollback: '복원',
    match_rollback: '복원',

    transaction_type: '구분',

    cash_out_cancel: '출금취소',

    cash_out_req: '출금신청',
    cash_in_req: '입금신청',

    admin_cancel: '관리자 취소',
    user_cancel: '유저 취소',

    memo: '메모',

    admin: `관리자`,

    bet_cancel: '배팅취소',

    home: '홈팀',
    away: '원정팀',
    draw: '적중특례'
  }
}
