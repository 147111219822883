import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,

    //= status
    all: `状態`,
    confirmed: `正常`,
    pending: `要請`,
    rejected: `拒否`,
    waiting: `待機`,
    deleted: `削除`,

    'cash_in/out': `入金/出金`,
    'manual_in/out': `支給/回収`,
    conversion: `ポイント転換`,
    commission: `コミッション`,

    confirm: `確認`,
    reject: `拒否`,
    lvl: `レベル. `,

    //= table
    edit_table_settings: `テーブル設定の修正`,
    no: `ナンバー`,
    created_date: `登録日時`,
    login_id: `ID`,
    login_name: `ニックネーム`,
    level: `レベル`,
    agent: `店舗`,
    type: `区分`,
    before_transfer: `以前の金額`,
    amount: `金額`,
    user_money: `変更金額`,
    status: `状態`,
    updated_date: `修正日時`,
    deleted_date: `削除日時`,
    show: `보이기`,
    hide: `감추기`,

    //= type
    win: `勝ち`,
    bet: `ベッティング`,
    cash_in: `入金`,
    cash_out: `出金t`,
    rollback: `回転`,
    user_cancel: `ベッティング取消`,
    modify: `修正`
  }
}
