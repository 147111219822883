<template>
  <div class="wrap__contents transactions-inner">
    <div class="toolbar">
      <div class="toolbar__items subnav">
        <select2
          ref="selectStatus"
          v-model="selectedStatus"
        >
          <option2
            value="all"
            :selected="selectedStatus === 'all'"
          >
            {{ translate(translations, 'all') }}
          </option2>

          <option2
            value="confirmed"
            :selected="selectedStatus === 'confirmed'"
          >
            {{ translate(translations, 'confirmed') }}
          </option2>

          <option2
            value="rejected"
            :selected="selectedStatus === 'rejected'"
          >
            {{ translate(translations, 'rejected') }}
          </option2>
        </select2>
      </div>

      <div class="toolbar__items searchbar">
        <search
          :translations="translations"
          :filter-by="filterBy"
          :date-by="dateBy"
          @refresh="search"
          @trigger="trigger"
          @clear="search"
        />
      </div>
    </div>

    <div
      class="wrap__inner"
      :class="{'data-loading': showDataLoading}"
    >
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <tbl
        v-else
        ref="tbl"
        :data-loading="isDataLoading"
      >
        <template slot="head">
          <tbl-td
            v-if="checkCol('id', tblSetting)"
            name="no"
            :sort="true"
            @click.native="sort('id')"
          >
            {{ translate(translations, 'no') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('created_at', tblSetting)"
            name="created-date"
            :sort="true"
            @click.native="sort('created_at')"
          >
            {{ translate(translations, 'created date') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('login_id', tblSetting)"
            name="login-id"
            :sort="true"
            @click.native="sort('users.login_id')"
          >
            {{ translate(translations, 'login id') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('login_name', tblSetting)"
            name="login-name"
            :sort="true"
            @click.native="sort('users.login_name')"
          >
            {{ translate(translations, 'login name') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('level', tblSetting)"
            name="level"
            :sort="true"
            @click.native="sort('level_id')"
          >
            {{ translate(translations, 'level') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('type', tblSetting)"
            name="type"
            :sort="true"
            @click.native="sort('type_id')"
          >
            {{ translate(translations, 'type') }}
          </tbl-td>

          <tbl-td
            name="memo"
            :sort="true"
            @click.native="sort('memo')"
          >
            {{ translate(translations, 'memo') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('old_balance', tblSetting)"
            name="before-transfer"
            :sort="true"
            @click.native="sort('old_balance')"
          >
            {{ translate(translations, 'before transfer') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('amount', tblSetting)"
            name="amount"
            :sort="true"
            @click.native="sort('amount')"
          >
            {{ translate(translations, 'amount') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('user_money', tblSetting)"
            name="user-money"
            :sort="true"
            @click.native="sort('users.amount')"
          >
            {{ translate(translations, 'user money') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('status', tblSetting)"
            name="status"
            :sort="true"
            @click.native="sort('status')"
          >
            {{ translate(translations, 'status') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('updated_at', tblSetting)"
            name="updated-date"
            :sort="true"
            @click.native="sort('updated_at')"
          >
            {{ translate(translations, 'updated date') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row
            v-for="(transfer, x) in transfers"
            :key="transfer.id"
            :class="{ 'is-test-user': transfer.user ? transfer.user.isTester : false }"
          >
            <tbl-td
              v-if="checkCol('id', tblSetting)"
              name="no"
            >
              {{ generateNumber(x, params.page, params.rows, params.sort, listCount || 0) }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('created_at', tblSetting)"
              name="created-date"
            >
              {{ transfer.created_at | date }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('login_id', tblSetting)"
              name="login-id"
              @click.native="openUser(transfer.user.id, 'bet-history/all')"
            >
              {{ transfer.user.login_id }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('login_name', tblSetting)"
              name="login-name"
              @click.native="openUser(transfer.user.id, 'bet-history/all')"
            >
              {{ transfer.user.login_name }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('level', tblSetting)"
              name="level"
            >
              {{ translate(translations, 'lvl') }}{{ transfer.user.level_id }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('type', tblSetting)"
              name="type"
            >
              {{ translate(translations, transfer.transaction_type) }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('type', tblSetting)"
              name="type"
              @click.native="handleDirectLinkFunc(transfer.memo, transfer.user, transfer.transaction_type)"
              v-html="translate(translations, transfer.memo) || '-'"
            />

            <tbl-td
              v-if="checkCol('old_balance', tblSetting)"
              name="before-transfer"
              class="is-amount"
            >
              {{ transfer.old_balance | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('amount', tblSetting)"
              name="amount"
              class="is-amount"
            >
              {{ transfer.amount | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('user_money', tblSetting)"
              name="user-money"
              class="is-amount"
            >
              {{ transfer.new_balance | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('status', tblSetting)"
              name="status"
            >
              <select2
                v-if="transferList[x] && (transferList[x].status.toLowerCase() === 'p' || transferList[x].status.toLowerCase() === 'w') && checkReadWrite"
                ref="status"
                v-model="transfer.status"
                :with-reset="true"
                @change.native="setSelected(transfer.id, 'status')"
              >
                <option2
                  v-for="status in transferStatus[transfer.id]"
                  :key="status"
                  :value="status"
                  :selected="transfer.status === status"
                >
                  {{ translate(translations, status, 'Status') }}
                </option2>
              </select2>

              <template v-else>
                {{ translate(translations, transfer.status, 'Status') }}
              </template>
            </tbl-td>

            <tbl-td
              v-if="checkCol('updated_at', tblSetting)"
              name="updated-date"
            >
              <template
                v-if="transferList[x] && transferList[x].status.toLowerCase() !== 'p' && transferList[x].status.toLowerCase() !== 'w'"
              >
                {{ transfer.updated_at | date }}
              </template>
              <template v-else>
                -
              </template>
            </tbl-td>
          </tbl-row>
        </template>

        <template slot="footer">
          <pagination
            v-model="params.page"
            :total="listCount"
            :rows="params.rows"
            @page="trigger"
          />
        </template>
      </tbl>
    </div>

    <table-settings
      v-if="modConfig.tableConfig"
      :translations="translations"
      :show-tbl-settings="showTblSettings"
      :all-cols="allCols"
      :tbl-setting="tblSetting"
      :activate="showTblSettings"
      @activate="showTblSettings = $event"
      @load="showLoad()"
    />

    <export-data
      module="transaction"
      :translations="translations"
      :all-cols="allCols"
      :activate="showExport"
      @activate="showExport = $event"
    />
  </div>
</template>

<script>
import { transfers } from '@/socket'
import { mapState, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

//= mixins
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'
import { checkAll } from '@/assets/js/mixins/base/CheckAll'
import siteDefaults from '@/assets/js/mixins/base/SiteDefaults'

//= components
import Checkbox from '@/components/base/Checkbox'
import Pagination from '@/components/base/Pagination'
import Modal from '@/components/base/Modal'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'
import ExportData from '@/components/base/import-export/Export'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= translation
import { translations } from '@/assets/js/translations/Transactions'

export default {
  name: 'TransactionList',

  components: {
    Pagination,
    Checkbox,
    Modal,
    Search,
    CheckDataList,
    ExportData
  },

  filters: {
    FormatAmount
  },

  mixins: [
    getListStatus,
    siteDefaults,
    checkAll
  ],

  data () {
    const params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc'
      },
      this.$route.query
    )

    for (const key in params) {
      if (params.hasOwnProperty(key) && !isNaN(parseFloat(params[key]))) {
        params[key] = parseFloat(params[key])
      }
    }

    return {
      translations,
      params,

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,
      showTblSettings: false,
      showExport: false,

      selectAll: false,
      status: '',
      type: '',
      page: 1,
      stats: ['C', 'D', 'R'],
      transfers: [],
      selected: [],

      filterBy: [
        {
          value: 'users.login_id',
          display: 'login id'
        },
        {
          value: 'users.login_name',
          display: 'login name'
        },
        {
          value: 'level_id',
          display: 'level'
        }
      ],

      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        },
        {
          value: 'updated_at',
          display: 'updated date'
        }
      ],

      tblSetting: 'transfer_list',
      allCols: [
        {
          display: 'no',
          value: 'id'
        },
        {
          display: 'created date',
          value: 'created_at'
        },
        {
          display: 'login id',
          value: 'login_id'
        },
        {
          display: 'login name',
          value: 'login_name'
        },
        {
          display: 'level',
          value: 'level'
        },
        {
          display: 'type',
          value: 'type'
        },
        {
          display: 'before transfer',
          value: 'old_balance'
        },
        {
          display: 'amount',
          value: 'amount'
        },
        {
          display: 'user money',
          value: 'user_money'
        },
        {
          display: 'status',
          value: 'status'
        },
        {
          display: 'updated date',
          value: 'updated_at'
        }
      ]
    }
  },

  computed: {
    ...mapState('transaction', {
      transferList: 'transfers',
      searchTransfers: 'listTransfers',
      listCount: 'count'
    }),

    getRouteStatus () {
      return this.$route.params.status
    },

    transferStatus () {
      const status = {}

      this.transfers.forEach(e => {
        switch (e.status.toLowerCase()) {
          case 'c':
            status[e.id] = ['B']
            break
          case 'w':
          case 'p':
            status[e.id] = ['C', 'R']
            break
          case 'b':
            status[e.id] = ['C']
            break
          case 'r':
            status[e.id] = ['P', 'W']
            break
        }
      })

      return status
    }
  },

  watch: {
    '$route' (to) {
      this.type = to.params.type
      this.status = to.params.status
      this.selectedStatus = this.status

      this.$refs.selectStatus.selected.label = this.translate(translations, this.status)

      this.search(this.generate(to.query))
    }
  },

  async created () {
    this.search()

    await this.listTransfers({
      type: this.$route.params.type,
      status: this.$route.params.status,
      params: this.params
    })
    this.searchData = this.searchTransfers
  },

  mounted () {
    transfers.on('cash-in', this._wsTransactionCashIn)
    transfers.on('cash-out', this._wsTransactionCashOut)
  },

  beforeDestroy () {
    transfers.removeListener('cash-in', this._wsTransactionCashIn)
    transfers.removeListener('cash-out', this._wsTransactionCashOut)
  },

  methods: {
    ...mapActions('transaction', { getTransfers: 'get', listTransfers: 'list' }),
    ...mapActions('transaction', { patch: 'update' }),
    ...mapMutations('transaction', ['SET_TRANSFERS']),
    ...mapActions('meta', { getAllMeta: 'get', getCashInfo: 'getCashInfo' }),

    async _wsTransactionCashIn (data) {
      if (this.$route.params.type === 'cashout') return
      data = JSON.parse(data)

      const test = JSON.parse(JSON.stringify(this.transferList))
      test.pop()
      test.unshift(data)
      this.SET_TRANSFERS(test)

      this.transfers = JSON.parse(JSON.stringify(this.transferList))
    },

    async _wsTransactionCashOut (data) {
      if (this.$route.params.type === 'cashin') return

      data = JSON.parse(data)

      const test = JSON.parse(JSON.stringify(this.transferList))
      test.pop()
      test.unshift(data)
      this.SET_TRANSFERS(test)

      this.transfers = JSON.parse(JSON.stringify(this.transferList))
    },

    tempTotal (transfer) {
      if (transfer.status.toLowerCase() !== 'c') {
        return 0
      }
      if ([1, 3].indexOf(transfer.type_id) > -1) {
        return transfer.amount + transfer.old_balance
      }
      return transfer.old_balance - transfer.amount
    },

    async trigger (query) {
      if (_.isEqual(query, this.params)) {
        this.search()
        return
      }
      this.params = query || this.params
      this.$router.push({ query: this.params })
    },

    async search (query) {
      this.type = this.$route.params.type
      this.status = this.$route.params.status

      this.params = query || this.params
      this.selected = []
      this.selectAll = false

      // == flag reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      await this.getTransfers({
        type: this.type || 'all',
        status: this.status || 'all',
        params: this.params
      })

      this.transfers = JSON.parse(JSON.stringify(this.transferList))

      // == flags set
      this.isDataLoading = false
      this.showDataLoading = false
      if (!this.transfers.length) {
        this.isEmpty = true
      }
    },

    setSelected (id, type) {
      this.$nextTick(() => {
        const unchanged = this.transferList.find(e => { return e.id === id })
        const changed = this.transfers.find(e => { return e.id === id })
        // == validation for changed data
        if (changed[type] !== unchanged[type]) {
          // == pushing modified
          this.selected.push(id)
          this.selected = _.uniq(this.selected)
        } else {
          const index = this.selected.indexOf(id)
          if (index > -1) {
            // == removing item not edited if exists
            this.selected.splice(index, 1)
          }
        }
      })
    },

    async update () {
      const success = async () => {
        this.showDataLoading = true
        const edited = this.transfers.filter(e => {
          return this.selected.indexOf(e.id) > -1
        })

        const stats = []
        for (let x = 0; x < edited.length; x++) {
          try {
            await this.patch({
              status: edited[x].status,
              id: edited[x].id,
              item: edited[x]
            })

            stats.push(parseInt(edited[x].type_id))
          } catch (err) {

          }
        }
        let path = ''
        if (_.uniq(stats).length > 1) {
          path = '/transfers/all/all'
        } else {
          const stat = stats[0] === 1 ? 'cashin' : 'cashout'
          path = `/transfers/${stat}/all`
        }

        if (this.$route.path !== path) {
          this.$router.push({ path })
          return
        }
        this.selectAll = false

        await this.search()
        this.showDataLoading = false

        if (!this.transfers.length) {
          this.isEmpty = true
        }
      }

      const cancel = async () => {
        const edited = this.transfers.filter(e => {
          return this.selected.indexOf(e.id) > -1
        })

        for (var x = 0; x < this.transfers.length; x++) {
          if (this.selected.indexOf(this.transfers[x].id) > -1) {
            const edit = this.transferList.find(a => {
              return a.id === this.transfers[x].id
            })
            this.transfers[x].status = edit.status
            this.$refs.status[0].reset = true
          }
        }
      }

      // Are you sure you want to modify the following transaction(s)?
      const swalMsg = ''
      this.$alert('prompt', swalMsg, {}, { success, cancel }, 'modify')
    },

    updateAll (status) {
      let edited = this.transfers.filter(e => {
        return this.selected.indexOf(e.id) > -1
      })

      edited = edited.filter((e, x) => {
        if (e.status.toLowerCase() === 'c') {
          const f = this.transferList.find(trans => { return trans.id === e.id })
          return f.status !== e.status
        }
        return e
      })

      if (!edited.length) {
        const swalMsg = 'try again'
        this.$alert('error', swalMsg)
        return
      }

      edited.forEach(e => {
        e.status = status
      })

      this.selectAll = false
      this.update()
    },

    sort (sortBy) {
      if (this.params.sort === 'asc') {
        this.params.sort = 'desc'
      } else {
        this.params.sort = 'asc'
      }

      this.params.sort_by = sortBy

      if (!this.showDataLoading) {
        this.showDataLoading = true
      }

      this.$router.push({ query: this.params })
    },

    openUser (id, subtype) {
      window.open(
        `${this.appUrl}/user/${id}/${subtype}`,
        '',
        `width=${1400},height=${window.innerHeight}`
      )
    },

    checkButtons (key) {
      if (this.$route.params.status === 'all') {
        return true
      }

      let allowed = {
        confirmed: [],
        pending: ['confirm', 'waiting', 'modify', 'reject'],
        waiting: ['confirm', 'reject'],
        rejected: ['confirm'],
        deleted: []
      }

      if (this.$route.params.type === 'cashout') {
        allowed = {
          confirmed: [],
          pending: ['confirm', 'waiting', 'modify', 'reject'],
          waiting: ['confirm', 'reject'],
          rejected: [],
          deleted: []
        }
      }

      if (allowed[this.$route.params.status].indexOf(key) > -1) {
        return true
      }

      return false
    },

    handleDirectLinkFunc (memo, user, tt) {
      if (!memo) return
      if (!_.includes(['win', 'bet', 'rollback', 'bet_cancel'], tt)) return

      const status = 'all'
      memo = memo.replace(/(<([^>]+)>)/gi, '|')
      memo = memo.split('|').filter(Boolean)

      if (memo.length === 1) {
        return console.warn('uhm. i don\'t recognize this format.')
      }

      if (memo.length) {
        memo[1] = memo[1].replace(/\D/g, '')
      }

      let [type, betGroupID] = memo

      betGroupID = typeof betGroupID === 'string'
        ? parseInt(betGroupID)
        : betGroupID

      const bgId = betGroupID
      const uId = user.id

      this.openNewWindow(`/bet-history/transaction/${type}/${status}`, { uId, bgId, type: 'cash' })
    }
  }
}
</script>
